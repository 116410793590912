<template>
	<div class="mainContent">
		<el-form ref="checkForm" :model="formInline" label-width="100px">
			<el-row class="search">
				<el-col :span="18">
					<el-row>
						<el-col :span="6">
							<el-form-item label="角色名称:" prop="roleName">
								<el-input
									v-model="formInline.roleName"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="所属机构:" prop="orgId">
								<selectOrg
									placeholder="选择所属机构"
									ref="searchOrg"
									@select="searchOrg"
								></selectOrg>
							</el-form-item>
						</el-col>
					</el-row>
				</el-col>
				<el-col :span="6">
					<el-row>
						<el-col :span="24">
							<el-form-item>
								<el-button type="primary" @click="search"
									>查询</el-button
								>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="reset"
									>重置</el-button
								>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-button type="primary" @click="add"
								>新增</el-button
							>
							<el-button type="primary" @click="batchDelete"
								>批量删除</el-button
							>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</el-form>

		<div class="jy_table">
			<ctable
				ref="ctable"
				selectMode="check"
				:columns="column"
				:data="tableData"
				:total="total"
				:btnList="btnList"
				operateWidth="300"
				@cellClick="cellBtnClick"
				@update="initTable"
			>
			</ctable>
		</div>
		<!-- 新增修改 -->
		<cdialog
			:show="showDialog"
			:columns="column"
			title="角色"
			width="30%"
			ref="cdialog"
			:data="selData"
			:isEdit="isEdit"
			@close="closeDialog"
			@verify="saveOrUpdate"
		>
			<template slot="orgId">
				<selectOrg
					placeholder="选择所属机构"
					:currentOrgName="selData.orgName"
					ref="selectOrg"
					@select="selectSlotOrg"
				></selectOrg>
			</template>
			<template slot="menuIds">
				<el-tree
					:data="permsData"
					ref="jurisdictionTree"
					show-checkbox
					node-key="id"
					check-strictly
					default-expand-all
					:props="defaultProps"
					:default-checked-keys="selData.menuIds"
					style="min-height: 200px; max-height: 300px; overflow: auto"
				>
				</el-tree>
			</template>
		</cdialog>
	</div>
</template>

<script>
import selectOrg from "@/components/dialog/selectOrg";

export default {
	name: "index",
	components: {
		selectOrg,
	},
	methods: {
		batchDelete() {
			const selData = this.$refs.ctable.selection;
			if (selData.length <= 0) {
				this.$message.warning({
					message: "请选择要删除的列",
					duration: 1000,
				});
				return;
			}
			this.$confirm("是否删除这些角色", "删除", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(async () => {
				const result = await this.$http({
					url: "/sys/role/delete",
					data: {
						ids: selData.map((v) => v.roleId),
					},
				});
				this.$message.success({
					message: result.msg,
					duration: 1000,
				});
				this.initTable();
			});
		},
		reset() {
			this.formInline = {
				roleName: "",
				orgId: "",
				roleType: "",
			};
			if (this.$refs.searchOrg) {
				this.$refs.searchOrg.reset();
			}
			this.$refs.ctable.reset();
			this.initTable();
		},
		add() {
			this.showDialog = true;
		},
		closeDialog() {
			this.selData = {};
			if (this.$refs.selectOrg) {
				this.$refs.selectOrg.reset();
            }
            if (this.$refs.jurisdictionTree) {
                this.$refs.jurisdictionTree.setCheckedKeys([])
            }
			this.isEdit = false;
			this.showDialog = false;
		},
		search() {
			this.$refs.ctable.reset();
			this.initTable();
		},
		initTable() {
			const page = this.$refs.ctable.getPager();
			this.$http({
				url: "/sys/role/queryPage",
				data: {
					...this.formInline,
					...page,
				},
			}).then((res) => {
				this.tableData = res.detail.list;
				this.total = res.detail.total;
			});
		},
		saveOrUpdate(data) {
            const menuIds = this.$refs.jurisdictionTree.getCheckedKeys();
			if (this.isEdit) {
				this.$http({
					url: "/sys/role/update",
					data: {
                        ...data,
                        menuIds: menuIds.join(",")
                    },
				}).then((res) => {
					if (res.code === "0") {
						this.showDialog = false;
						this.$message({
							message: "更新成功",
							type: "success",
							duration: 1000,
						});
						this.closeDialog();
						this.initTable();
					}
				});
			} else {
				this.$http({
					url: "/sys/role/save",
					data: {
                        ...data,
                        menuIds: menuIds.join(",")
                    },
				}).then((res) => {
					if (res.code === "0") {
						this.showDialog = false;
						this.$message({
							message: "添加成功",
							type: "success",
							duration: 1000,
						});
						this.closeDialog();
						this.initTable();
					}
				});
			}
		},
		selectSlotOrg(data) {
			const form = this.$refs.cdialog.getFormData();
			this.$set(form, "orgId", data.orgId);
		},
		cellBtnClick(row, btnIndex, item) {
			if (btnIndex === "edit") {
				this.showDialog = true;
				this.selData = item;
				this.isEdit = true;
			} else if (btnIndex === "del") {
				this.$confirm("是否要删除此角色?", "删除", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$http({
						url: "/sys/role/delete",
						data: {
							ids: item.roleId,
						},
					}).then((res) => {
						if (res.code === "0") {
							this.$message.success({
								message: "删除成功",
								type: "success",
								duration: 1000,
							});
							this.initTable();
						}
					});
				});
			}
		},
		searchOrg(data) {
			this.formInline.orgId = data.orgId;
		},
		initBtns() {
			this.btnList.push({
				index: "edit",
				title: "编辑",
			});
			this.btnList.push({
				index: "del",
				title: "删除",
			});
		},
	},
	data() {
		return {
			formInline: {
				roleName: "",
				orgId: "",
			},

			permsData: [],
			defaultProps: {
				children: "children",
				label: "text",
			},

			isEdit: false,
			showDialog: false,
			selData: {},

			tableData: [],
			total: 0,
			column: [
				{
					prop: "roleName",
					title: "角色名称",
					tag: "el-input",
					props: {
						maxlength: 50,
					},
				},
				{
					prop: "orgId",
					title: "所属机构",
					formatter: (index, key, item) => {
						return item["orgName"];
					},
					tag: "slot",
				},
				{
					prop: "menuIds",
					title: "分配权限",
					unShow: true,
					unNeed: true,
					tag: "slot",
				},
				// {
				//     prop: "description",
				//     title: "角色说明",
				//     tag: "el-input",
				//     unNeed: true,
				//     props: {
				//         maxlength: 50
				//     }
				// },
				{
					prop: "remark",
					title: "备注",
					tag: "el-input",
					props: {
						type: "textarea",
						maxlength: 50,
					},
					unNeed: true,
				},
				{
					prop: "createTime",
					title: "创建时间",
					// formatter: (index,key,item) => {
					//   return formatDate(item[key],'yyyy-MM-dd hh:mm:ss')
					// }
				},
				{
					prop: "createUserName",
					title: "创建者",
				},
			],
			btnList: [],
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.initTable();
		});
		this.initBtns();
		this.$http({
			url: "/sys/menu/tree",
		}).then((res) => {
			this.permsData = res.detail;
		});
	},
};
</script>
